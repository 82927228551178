import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  icon: {
    width: 150,
  },
  container: {
    height: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: '0!important',
    paddingRight: '0!important',
    position: 'relative',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  error: {
    textAlign: 'center',
  },
});

export default useStyles;
