export const LABELS = {
  SELECTION_TITLE: 'Choose Method',
  DONATE_CRYPTO: 'Crypto',
  CREDIT_CARD_DONATION: 'Card',
  STOCK_DONATION: 'Stock',
  DAF_DONATION: 'DAF',
  LOADING_TEXT: 'Loading',
  CREDIT_CARD_ALT: 'Credit card',
  STOCKS_ALT: 'Stocks',
  ACH_DONATION: 'ACH',
  UNKNOWN: 'unknown',
  DEFAULT_ERROR_MESSGAE: 'Something went wrong. Please try again later.',
};

export const KEYS = {
  GRID_SIZE_FULL: 12,
  GRID_SIZE_HALF: 6,
};

export const gridLayout = {
  1: [KEYS.GRID_SIZE_FULL],
  2: [KEYS.GRID_SIZE_FULL, KEYS.GRID_SIZE_FULL],
  3: [KEYS.GRID_SIZE_FULL, KEYS.GRID_SIZE_HALF, KEYS.GRID_SIZE_HALF],
  4: [KEYS.GRID_SIZE_FULL, KEYS.GRID_SIZE_HALF, KEYS.GRID_SIZE_HALF, KEYS.GRID_SIZE_FULL],
  5: [KEYS.GRID_SIZE_FULL, KEYS.GRID_SIZE_HALF, KEYS.GRID_SIZE_HALF, KEYS.GRID_SIZE_HALF, KEYS.GRID_SIZE_HALF],
};

export default LABELS;
